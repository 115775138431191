@import 'css/custom/_required.scss';

.container {
  align-items: center;
  display: flex;
}

.label{
  font-size: $font-size-lg;
  margin-right: $btn-padding-x;
  opacity: .75;
}

.toggle {
  padding: 0;

  @include hover {
    .icon {
      opacity: 1;
    }
  }
}

.icon {
  opacity: .75;
  transition: $transition-base;
}

.menu {
  border: 0;
  z-index: $zindex-fixed + 1;

  &, & *:global(.dropdown-menu-inner) {
    border-radius: 0;
  }
}

.popover {
  right: -$btn-padding-x !important;
  min-width: calc(100% + #{$btn-padding-x * 2});
  transform: none !important;
  left: auto !important;
  top: auto !important;

  &:after {
    @include pseudo-element;

    border-color: transparent transparent $white;
    border-style: solid;
    border-width: 1rem 1rem;
    right: $btn-padding-x;
    top: -2rem;
  }
}

.item {
  display: flex;
  margin-left: $btn-padding-x;

  &:not(:first-child) {
    border-top: $border-width solid $border-color;
  }
}

.button {
  background-color: transparent !important;
  border: 0;
  border-radius: 0 !important;
  color: $body-color !important;
  flex-grow: 1;
  font-weight: $font-weight-base;
  margin-left: -$btn-padding-x;
  padding: $menu-padding-y $menu-padding-x;
  width: auto !important;

  &:global(.btn) {
    max-height: inherit !important;
    min-height: inherit !important;
  }

  :global(.sprite) {
    color: $secondary;
  }

  @include hover {
    &:not(:global(.disabled)) {
      background-color: $accent !important;
      color: $primary !important;
      position: relative;
      z-index: 1;

      .item:not(:first-child) > & {
        margin-top: -$border-width;
        padding-top: $menu-padding-y + $border-width !important;
      }

      .item:not(:last-child) > & {
        margin-bottom: -$border-width;
        padding-bottom: $menu-padding-y + $border-width !important;
      }

      :global(.sprite) {
        color: $primary !important;
      }
    }
  }
}
