@import 'css/custom/_required.scss';

.dropdown {
  :global(.dropdown-menu) {
    :global(.btn) {
      @include caption;
  
      :global(.btn-label) {
        text-transform: uppercase;
      }
    }
  }
}
