@import 'css/custom/_required.scss';

.toggle {
  @include desktop {
    border-right: $border-width solid transparentize($white, .75);
    margin-left: -$header-padding;

    :global(.btn-icon) {
      opacity: .75;
      transition: $transition-base;
    }

    &:hover {
      :global(.btn-icon) {
        opacity: 1;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    display: none;
  }
}
