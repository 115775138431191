html {
  font-size: $body-size;
  -webkit-font-smoothing: antialiased;
}

#root {
  min-height: 100vh;
//  overflow-y: scroll;
//  scroll-behavior: smooth;
}

main {
  max-width: 100vw;
}

a, button {
  background: none;
  border: 0;
  cursor: pointer;
  transition: $transition-base;

  -webkit-appearance: initial !important;

  &:disabled {
    cursor: default;
  }

  @include hover-focus-active {
    outline: 0;
    text-decoration: none;
  }
}

p, ul, ol, blockquote {
  &:last-child {
    margin-bottom: 0 !important;
  }
}

hr {
  width: 100%;
}

input,
textarea,
select{
  @mixin autofill {
    background-color: $input-bg;
    border-color: $primary;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.row,
.form-row {
  flex-grow: 1;
}
