@import 'css/custom/_required.scss';

$number-width: calc(#{$table-padding-x} + 4rem);

.row {
  background: $light;
  transition: $transition-base;

  @include mobile {
    box-shadow: $box-shadow;
    padding: 0 $table-padding-x/2;

    &:not(:last-child) {
      margin-bottom: $page-padding-y-mobile;
    }

    &:hover {
      box-shadow: $box-shadow;
      background: $white;
    }
  }

  @include desktop {
    margin: (-$border-width) 0;
    padding: 0 $table-padding-x;

    &:hover {
      box-shadow: $box-shadow;
      background: $white;
      position: relative;
      z-index: $zindex-sticky -1;
    }
  }
}

.inner {
  transition: $transition-base;

  :global(.row) {
    //margin: 0 (-$table-padding-x/2);
  }

  @include desktop {
    border-bottom: 1px solid $border-color;
    border-top: 1px solid $border-color;

    &:hover {
      border-color: transparent;
    }
  }
}

.number {
  @include mobile {
    background-color: transparentize($secondary, .75);
    color: $dark;
    flex-grow: 0;
    font-size: $font-size-lg;
    padding: $table-padding-y 0;
    min-width: 100%;
    text-align: center;

    &:before {
      content: "#";
    }

    .row:hover & {
      background-color: transparentize($primary, .75);
      color: $primary;
    }

    @include media-breakpoint-up(sm) {
      min-width: 8rem;
    }
  }

  @include desktop {
    display: flex;
    line-height: 2rem;
    flex-grow: 0 !important;
    max-width: $number-width !important;
    min-width: $number-width !important;
    padding: $table-padding-y $table-padding-x/2 !important;
  }
}

.cols {
  @include mobile {
    min-width: 100%;
    padding: $table-padding-y $table-padding-x/2;
  }
}

.innerRow {
  margin: 0 (-$table-padding-x/2);

  & > * {
    padding: 0 ($table-padding-x/2);
  }

  @include mobile {
    .cols & {
      padding: $table-padding-y/2 0;
    }
  }
}

.actions {
  align-items: center;
  display: flex;
  max-height: 5rem;

  @include mobile {
    border-top: 1px solid $border-color;
    justify-content: center;
    margin-bottom: $table-padding-y/2;
    padding-top: $table-padding-y/2;
    min-width: 100%;

    & > * {
      margin: 0 $table-padding-x/2;
    }

    @include media-breakpoint-up(md) {
      justify-content: flex-start;
      padding-left: 33.333333%;
    }
  }

  @include desktop {
    flex-grow: 0 !important;

    @for $i from 1 through 6 {
      &.actions-#{$i} {
        max-width: 32px * $i + rem-to-px($table-padding-x);
        min-width: 32px * $i + rem-to-px($table-padding-x);
      }
    }
  }
}
