@import 'css/custom/_required.scss';
@import 'parts/interface/header/header.module.scss';

.container {
  align-items: center;
  background: $white;
  display: flex;
  justify-content: space-between;
  height: $header-height;
  width: 100%;

  @include tablet {
    padding: 0 $header-padding/2;
  }
}

.title {
  margin: 0 $header-padding/2;
}

.close {
  background: $light;
  color: $secondary;
}
