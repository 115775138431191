@import 'css/custom/_required.scss';

.toolbar {
  line-height: $btn-height-sm;
  margin-bottom: 2rem;

  span, em {
    color: $body-color;
  }

  :global(.form-label) {
    color: $body-color;
    font-weight: $font-weight-base;
  }
}

.break {
  @include media-breakpoint-down(sm) {
    min-width: 100%;
    padding: .5rem;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.divider {
  border-right: $border-width solid transparentize($secondary, .5);
  height: $btn-height-sm - $border-radius * 2;
  margin: 0 $form-grid-gutter-width/2;

  @include mobile {
    margin: 0 $form-grid-gutter-width-mobile/2;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.spacer {
  flex-grow: 1;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}
