@import 'css/custom/_required.scss';

.footer {
  color: $text-muted;
  display: flex;
  margin-top: auto;
  padding: $page-padding-y-mobile 0 0;

  p {
    margin-bottom: 0;
  }

  @include mobile {
    align-items: center;
    flex-direction: column;
  }

  @include desktop {
    justify-content: space-between;
    padding: $page-padding-y 0 0;
  }
}
