@import 'css/custom/_required.scss';
@import './listRow.module.scss';

.header {
  background: $light;
  border-bottom: 1px solid $border-color;
  border-top: 1px solid $border-color;
  display: block;
  padding: 0 2rem;
  position: sticky;
  top: $header-height;
  z-index: $zindex-dropdown - 1;

  @include hidden-mobile;

  & > :global(.row) {
    margin: 0 (-$table-padding-x/2);
  }
}

.col {
  align-items: center;
  display: flex;
  font-size: $font-size-sm;
  font-weight: $font-weight-bolder;
  line-height: 1.1em;
  min-height: $font-size-sm * 1.1 + 3rem;
  padding: 0 $table-padding-x/2;
}
