@import 'css/custom/_required.scss';

.group {
  padding: 0;

  &:not(.inline) > *:nth-child(2) {
    padding-top: $form-grid-gutter-width/2;
  }
}

.select, .picker {
  &.inline {
    min-width: 17rem;
  }
}

.picker {
  &:not(.inline) {
    .select ~ & {
      padding-top: 0;
    }
  }
}

.timePicker, .rangePicker {
  &:not(.vertical):not(.columns):not(.fullWidth) {
    max-width: ($input-max-width + $form-grid-gutter-width)/2;
  }

  &.vertical {
    max-width: 50%;
  }
}

.muted {
  color: $text-muted;
}
