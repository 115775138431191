@import 'css/custom/_required.scss';

.container {
  height: 100%;
  position: relative;

  @include tablet {
    padding: 6rem 0;
  }
}

.content {
  align-items: center;
  background: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  min-height: 100vh;
  padding: 6rem 0 0;

  @include tablet {
    max-width: 60rem;
    min-height: inherit;
    padding: 8rem 0 0;
  }
}

.header, .body, .info > div {
  max-width: 40rem;
  padding: 0 2rem;
  width: 100%;
}

.header {
  margin-bottom: 4rem;
}

.branding {
  padding: 0 0 4rem;
}

.subtitle {
  color: $secondary;
  margin-top: .8rem;
}

.alerts {
  margin-bottom: 4rem;
}

.footer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: $form-group-margin-bottom;

  :global(.btn) {
    width: auto !important;

    &:not(.back) {
      min-width: 50%;
    }

    @include media-breakpoint-down(xs) {

      &.back {
        :global(.sprite) {
          margin: 0 !important;
        }

        :global(.btn-label) {
          display: none;
        }
      }

      &:not(.back) {
        :global(.sprite) {
          display: none;
        }

        :global(.btn-label) {
          margin: 0 !important;
          justify-content: center;
        }
      }
    }
  }
}

.info {
  border-top: $border-width solid $border-color;
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  padding: 4rem 0;
  width: 100%;

  & > div {
    align-items: center;
    display: flex;
    justify-content: space-between;

    p {
      @include caption;
      margin: 0;
    }

    .copyright {
      text-transform: none;
    }

    .link {
      color: $primary;
      text-decoration: none;
    }
  }
}
