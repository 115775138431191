@import 'css/custom/_required.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  @include desktop {
    box-shadow: $box-shadow;
  }
}

.topButton {
  border-radius: 0;
  justify-content: center !important;
  margin: (-$page-padding-y-mobile) (-$page-padding-x-mobile) $page-padding-y-mobile;
  min-height: $btn-height * 1.25;
  min-width: 100vw;
  position: sticky;
  top: $header-height;
  z-index: $zindex-sticky;

  :global(.btn-label) {
    flex-grow: 0;
  }
}

.rows {
  @include desktop {
    //overflow: hidden;
  }
}

.message {
  align-items: center;
  background: $light;
  color: $text-muted;
  display: flex;
  flex-grow: 1;
  font-style: italic;
  justify-content: center;
  padding: $card-spacer-x;
}

.headerButton {
  position: fixed;
  right: 1.5rem;
  top: 1.5rem;
  z-index: $zindex-fixed;
}
