@import 'css/custom/_required.scss';

.navbar {
  background: $white;
  height: 100vh;
  max-height: 100vh;
  padding: 0;
  position: fixed;

  @include media-breakpoint-down(lg) {
    left: 0;
    transition: $transition-base;
    z-index: $zindex-fixed + 1;
  }

  @include mobile {
    width: 100%;

    &[data-show="false"] {
      left: -100%;
    }
  }

  @include tablet {
    border-right: $border-width solid $border-color;
    width: $menu-lg;

    &[data-show="false"] {
      left: -$menu-lg;
    }
  }

  @include media-breakpoint-up(xl) {
    //border-right: $border-width solid $border-color;
    flex-direction: column;
    left: 0 !important;
    width: $menu-lg;

    & + * {
      margin-left: $menu-lg;
    }
  }
}

.outer {
  border-top: $border-width solid $border-color;
  max-height: calc(100vh - #{$header-height});
  min-height: calc(100vh - #{$header-height});
}

.inner {
  width: 100%;
}

.nav {
  flex-direction: column;
  margin-bottom: $border-width;
  padding-left: $menu-padding-x;
}
