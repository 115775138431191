@import 'css/custom/_required.scss';

.contaner {
  display: flex;
  margin-bottom: $card-spacer-y;
  min-width: 100%;

  & > *:not(:last-child) {
    after: ", ";
    color: $text-muted;
    white-space: pre;
  }
}

.label {
  color: $text-muted;
}

.value {
  font-weight: $font-weight-bold;
}
