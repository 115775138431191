@import 'css/custom/_required.scss';

.title {
  color: $dark;
  font-weight: $font-weight-bolder;
  line-height: 4rem;

  &.pointer {
    cursor: pointer;
  }
}
