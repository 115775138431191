@import 'css/custom/_required.scss';

.container {
  display: inline-block;
  
  &.fullWidth {
    display: block;
  }

  &:first-child:not(:last-child) {
    margin-bottom: $input-padding-y * 2;
  }

  &:last-child:not(:first-child) {
    margin-top: $input-padding-y * 2;
  }
}

.button {
  :global(.form-control-error) & {
    border-color: $danger !important;
  }
}
