@import 'css/custom/_required.scss';

.button {
  color: $white !important;
  max-height: 5rem !important;
  min-height: 5rem !important;

  &.square,
  &:global(.btn-square) {
    max-width: 5rem !important;
    min-width: 5rem !important;
  }

  @include mobile {
    background-color: transparentize($white, .75) !important;
  }

  @include desktop {
    border-radius: 0;
    font-size: $font-size-lg;
    font-weight: $font-weight-bolder;
    max-height: $header-height !important;
    min-height: $header-height !important;

    &.square {
      max-width: $header-height !important;
      min-width: $header-height !important;
    }
  }

  @include hover {
  }

  @include active {
  }
}
