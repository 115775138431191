@import 'css/custom/_required.scss';

.header {
  align-items: center;
  display: flex;
  margin: 0 0 $page-padding-y-mobile;

  @include desktop {
    margin: 0 0 $page-padding-y;
  }
}

.tools {
  display: flex;
  flex-direction: row-reverse;
  margin-left: auto;

  & > *:not(:last-child) {
    margin-left: 2rem;
  }
}
