@import 'css/custom/_required.scss';
@import 'parts/interface/header/header.module.scss';

.container {
  align-items: center;
  display: flex;
  height: $header-height;
  justify-content: space-between;
  width: 100%;

  @include media-breakpoint-down(sm) {
    flex-direction: row-reverse;
  }

  @include media-breakpoint-up(md) {
    padding: 0 $menu-padding-x;
  }
}

.brand {
  display: block;
  padding: 0;
  margin: 0;
}

.tool {
  @include media-breakpoint-up(md) {
    width: auto;
  }
}

.placeholder {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.close {
  background: transparent;
  color: $secondary;

  @include media-breakpoint-down(sm) {
    background: $light;
  }

  @include media-breakpoint-up(xl) {
    display: none;
  }
}
