@import 'css/custom/_required.scss';

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: $card-spacer-y;

  & > * {
    flex-basis: 0;
    flex-grow: 0;

    &:not(:first-child) {
      margin-left: 20px;
    }

    &:first-child:not(:last-child) {
      margin-right: auto;
    }
  }

  @include desktop {
    & > *:not(.cancelButton) {
      max-width: 400px;
      min-width: calc(25% - 15px);
    }
  }
}
