@import 'css/custom/_required.scss';

.col {
  max-width: $label-width;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.buttons {
  //max-width: $input-max-width;
}
