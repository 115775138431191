@import 'css/custom/_required.scss';

.numberFormat {}

.empty {
  color: $text-muted
}

.nan {
  color: $text-muted
}

.dollars {
  white-space: nowrap;
}
