@import 'css/custom/_required.scss';

.hourPart {
  max-width: 10rem;

  &:first-child, &:last-child {
    max-width: 10rem + $form-grid-gutter-width/2;
  }
}

.closedFlag  {
  max-width: 10rem + $form-grid-gutter-width;
  min-width: 10rem + $form-grid-gutter-width;
}

.labelSpacer {
  max-width: $label-width;
}