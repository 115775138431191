@import 'css/custom/_required.scss';

.header {
  border-bottom: $border-width solid $border-color;
  display: flex;
  justify-content: space-between;

  &.pointer {
    cursor: pointer;
  }
}

.tools {
  align-items: center;
  display: flex;

  & > * {
    &:not(:last-child) {
      margin-right: .8rem;
    }
  }

  @include media-breakpoint-down($mobile-breakpoint-down) {
    :global(.btn) {
      display: inline-flex;
    }
  }
}
