h1 {
  line-height: $h1-line-height;
}

h2 {
  line-height: $h2-line-height;
}

h3 {
  line-height: $h3-line-height;
}

h4 {
  line-height: $h4-line-height;
}

h5 {
  line-height: $h5-line-height;
}

.caption {
  @include caption;
}
