// Font Awesome

// These classes modify Font Awesome icons.


.fa-lg {
  .text-center & {
    margin-left: -1rem;
  }

  .text-md-left & {
    @include media-breakpoint-up(md) {
      margin-left: 0;
    }
  }
}


.fa-stack {
  width: 2em;

  .svg-inline--fa.fa-stack-1x {
    margin: .5em !important;
    width: 1em;
  }

  .svg-inline--fa.fa-stack-2x {
    margin: 0 !important;
    width: 2em;
  }
}


// Buttons

.btn-square {
  .fa-fw {
    height: $sprite-size-lg;
    width: $sprite-size-lg;
  }
}


// Inputs

.input-group-addon {
  .fa, .svg-inline--fa.fa-fw {
    width: 100%;
  }
}
