@import 'css/custom/_required.scss';

.icon {
  color: $secondary;
  margin-right: $card-spacer-x/2;
  width: $card-spacer-x/2 !important;
}

.item {
  @include caption;
  border-bottom: 0;

  .icon {
    color: $gray-400;
  }

  &:not(:last-child) {
    margin-bottom: $card-spacer-y;
  }
}