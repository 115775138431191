@import 'css/custom/_required.scss';

.tooltip {
  align-items: center;
  display: inline-flex;
  max-height: 3.2rem;
}

.button {
  color: $body-color !important;
  height: 3.2rem !important;
  justify-content: center !important;
  max-height: 3.2rem !important;
  min-width: 3.2rem !important;

  @each $color, $value in $theme-colors {
    &.#{$color} {
      @include hover {
        color: $value !important;
      }
    }
  }

  @include disabled {
    color: $secondary !important;
  }
}

:global(.list-col) {
  & > .tooltip,
  & > .button {
    margin: -.6rem 0;
  }
}
