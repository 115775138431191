@import 'css/custom/_required.scss';

.toggle {
  flex-direction: column;
}

.icon {
  opacity: 1;
  transition: $transition-base;

  .link:hover > & {
    color: $primary;
  }

  @include mobile {
    margin-left: 1.6rem;
  }

  @include desktop {
    position: absolute;
    right: $menu-padding-x;
  }
}

.submenu {
  border-top: $border-width solid $border-color;
  margin-left: -$menu-padding-x;
  padding-left: px-to-rem($sprite-size-md) + $menu-padding-x * 2;
  transition: $transition-base;

  :global(.active) + &.level-0 {
    background: $component-active-bg;
  }

  @include mobile {
    padding-left: $menu-padding-x;

    &.level-1 {
      padding-left: 0;
    }
  }
}

.list {
  @include mobile {
    border-bottom: $border-width solid $border-color;
    border-top: $border-width solid $border-color;
    margin-bottom: $menu-padding-y;
    padding-top: $menu-padding-y;

    :global(.active) + .submenu.level-0 > & {
      border-bottom: 0;
      border-top: 0;
      margin-bottom: 0;
      padding-top: 0;
    }
  }
}
