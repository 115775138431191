@import 'css/custom/_required.scss';

.nav {
  margin-top: $page-padding-y-mobile;

  @include desktop {
    margin-top: $page-padding-y;
  }
}

.list {
  justify-content: center;
  margin: 0 -.75rem;
}

.item {
  align-items: center;
  display: flex;
  margin: 0 .75rem;
}

.active {
  align-items: center;
  border-radius: .4rem;
  display: flex;
  justify-content: center;
  min-width: 3rem;
}

.disabled {
  background-color: transparent !important;
  border-color: transparentize($secondary, .5) !important;
  color: $body-color !important;
}

.back,
.next {
  @include hidden-mobile;

  .disabled {
    color: transparentize($secondary, .25) !important;
  }
}
