@import 'css/custom/_required.scss';

.footer {
  border-top: $border-width solid $border-color;
  margin: $page-padding-y-mobile (-$page-padding-x-mobile) 0;
  padding: $page-padding-y-mobile $page-padding-x-mobile 0;

  @include tablet {
    margin: $page-padding-y (-$page-padding-x) 0;
    padding: $page-padding-y $page-padding-x 0;
  }
}
