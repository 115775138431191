@import 'css/custom/_required.scss';

.row {
  &:not(.inline):not(.vertical):not(.columns):not(.fullWidth) {
    @include media-breakpoint-up(md) {
      max-width: $input-max-width + $form-grid-gutter-width;
      width: $input-max-width + $form-grid-gutter-width;

      @include mobile {
        max-width: $input-max-width + $form-grid-gutter-width-mobile;
        width: $input-max-width + $form-grid-gutter-width-mobile;
      }
    }
  }
}

.textarea {
  &:global(.form-control) {
    height: calc(#{$input-line-height * 1em * 3} + #{$input-padding-y * 2} + #{$input-height-border});
    min-height: $input-height;

    &.sm {
      height: calc(#{$input-line-height-sm * 1em * 3} + #{$input-padding-y-sm * 2} + #{$input-height-border});
      min-height: $input-height-sm;
    }
  }
}

.disabled {
  resize: none;
}
