@import 'css/custom/_required.scss';

.modal {
  @include media-breakpoint-down(xs) {
    :global(.modal-footer .row) {
      flex-direction: column-reverse;
    }  
  }
}

.col {
  display: flex;
  justify-content: center;

  :global(.btn) {
    width: auto !important;
  }
}
