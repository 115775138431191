@import 'css/custom/_required.scss';

.col {
  min-width: 100%;
}

.container {
  color: $text-muted;
  padding: $input-padding-y + $input-border-width 0;

  &.disabled {
    color: $border-color;
  }
}

.btn:global(.btn-primary) {
  &:disabled {
    color: $text-muted !important;
  }

  &.disabled {
    color: $border-color !important;
  }
}
