@import 'css/custom/_required.scss';

.header {
  align-items: center;
  background-color: $primary;
  color: $white;
  display: flex;
  justify-content: space-between;
  min-height: $header-height;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $zindex-fixed;

  * {
    white-space: nowrap;
  }

  @include desktop {
    padding: 0 $header-padding;
  }

  @include media-breakpoint-up(xl) {
    width: calc(100% - #{$menu-lg});
  }
}

.tool {
  align-items: center;
  display: flex;
  height: $header-height;
  justify-content: center;
  width: $header-height;
}

.tools {
  align-items: center;
  display: flex;

  & > *:not(:last-child) {
    margin-right: $header-padding;
  }
}
