@import 'css/custom/_required.scss';

.item {
  align-items: center;
  display: flex;
  line-height: $input-line-height;
  padding: $input-padding-y + $border-width 0;
  width: 100%;

  &:not(:last-child) {
    padding-bottom: 0;
  }

  & > *:last-child:not(:first-child) {
    flex-shrink: 0;
    margin-left: auto;
  }

  &.plaintext {

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.info { 
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: $input-font-size;
  font-weight: $font-weight-bolder;
  line-height: $input-line-height;
  word-break: break-all;

  & > *:not(:last-child) {
    margin-right: 1.2rem;
  }
}

.label {
  .error & {
    color: $danger;
  }
}

.tools {
  display: flex;
  margin-left: 2rem;
}
