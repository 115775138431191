@import 'css/custom/_required.scss';

$checkbox-size: 1.8rem;

.container {
  display: flex;
  height: 100%;

  @include mobile {
    justify-content: space-between;
  }
}

.checkbox {
  align-items: flex-start;
  display: flex;
  padding-left: 0;

  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.input {
  cursor: pointer;
  height: $checkbox-size;
  margin: $input-padding-y + $input-border-width 0;
  opacity: 0;
  width: $checkbox-size;
  z-index: 1;

  &.disabled {
    cursor: inherit;

    &:checked {
      & ~ .sprite {
        color: $input-disabled-border-color !important;
      }
    }
  }

  &[type="radio"] {
    & ~ .sprite {
      border-radius: 100%;
    }
  }

  &:checked {
    & ~ .sprite {
      background-color: $white;
      color: $primary !important;
    }

    & ~ .label {
      color: $dark;
      font-weight: $font-weight-bolder;
    }
  }

  &:not(:checked) {
    & ~ .sprite {
      :global(.sprite) {
        opacity: 0;
      }
    }

    &:hover {
      &:not(:disabled) ~ .sprite {
        :global(.sprite) {
          opacity: 1;
        }
      }
    }
  }

  &:disabled {
    & ~ .sprite {
      border-color: $input-disabled-border-color;
      color: $border-color;
    }

    & ~ .label {
      color: $border-color;
    }
  }
}

.sprite {
  background-color: $input-empty-bg;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius / 2;
  color: $input-border-color;
  height: $checkbox-size;
  margin: $input-padding-y + $input-border-width 0;
  width: $checkbox-size;

  &.error {
    border-color: $danger !important;
  }

  :global(.sprite) {
    transition: opacity $transition-time;
  }

  :global(.form-control-error) & {
    border-color: $danger !important;
  }
}

.label {
  color: $secondary;
  font-size: $input-font-size;
  line-height: $input-line-height;
  margin-left: 12px;
  padding: $input-padding-y + $input-border-width 0;

  .sm & {
    padding: $input-padding-y-sm + $input-border-width 0;
  }
}

.formGroup {
  padding: 0 !important;

  &:not(.fullWidth) {
    max-width: 120px;
  }

  &.vertical.right {
    margin-left: auto !important;
    min-width: 50%;
    padding-left: $form-grid-gutter-width/2 !important;
  }
}

.formControl {
  &.right {
    text-align: right;
  }
}

.controlContainer {
  margin-left: 2rem;
}
