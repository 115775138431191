@import 'css/custom/_required.scss';

.items {
  &:not(.vertical):not(.columns):not(.fullWidth) {
    @include media-breakpoint-up(md) {
      max-width: $input-max-width + $form-grid-gutter-width;
    }
  }
  }

.itemControls {
  align-items: flex-start;
  flex-wrap: nowrap;

  & > * {
    flex-grow: 1 !important;
    flex-shrink: 1 !important;
  }
}

.deleteColumn {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  max-width: px-to-rem($sprite-size-md) + $form-grid-gutter-width;
}

.deleteButton {
  min-height: $input-height !important;
}