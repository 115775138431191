@import 'css/custom/_required.scss';

.outer {
  max-height: 100%;
  min-height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.inner {
  min-height: 100%;
  min-width: 100%;
  position: absolute;

  &.disabled {
    position: inherit;
  }

  &.horizontal {
//    max-height: 100%;
  }

  &.vertical {
    display: flex;
    max-width: 100%;
  }
}

.tray {
  &.vertical {
    flex-grow: 1;
  }
}
