.react-pdf__message {
  font-size: $font-size-base;
  line-height: $line-height-base;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  width: auto;
//  padding: map-get($spacers, 1) map-get($spacers, 1);

  @include media-breakpoint-up(md) {
//    padding: map-get($spacers, 4) map-get($spacers, 5);
  }

  @include media-breakpoint-up(md) {
//    padding: map-get($spacers, 5) map-get($spacers, 5);
  }
}

.react-pdf__Document {
  height: 100%;
  
  .scroll-box-y & {
    width: 100% !important;
  }

  & > div {
    &:not(:first-child) {
      border-top: 1px solid $border-color;
    }
  }
}

.react-pdf__message {
  height: 100%;
}

.react-pdf__Page__canvas {
  height: 100% !important;
  width: 100% !important;
}
