@import 'css/custom/_required.scss';

.card {
  box-shadow: $box-shadow;

  & + .card {
    margin-top: $card-spacer-y;
  }
}

.subtitle {
  color: $dark;
  font-weight: $font-weight-bold;

  &:not(:last-child) {
    margin-bottom: $card-spacer-y
  }
}

.divider {
  border-top: $border-width solid $border-color;
  flex-grow: 1;
  height: 0;
  margin: $card-spacer-x (-$card-spacer-x);
  min-width: calc(100% + #{$card-spacer-x/2});

  :global(.form-row) > & {
    margin: ($card-spacer-x - $form-group-margin-bottom/2) (-$card-spacer-x);
  }
}
