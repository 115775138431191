@import 'css/custom/_required.scss';

.sidebar {
  background: $sidebar-bg;
  height: 100vh;
  max-height: 100vh;
  margin: 0 !important;
  position: fixed;
  right: 0;
  top: 0;
  transition: $transition-base;
  z-index: $zindex-modal-backdrop - 1;

  @include mobile {
    width: 100%;

    &[data-show="false"] {
      right: -100%;
    }
  }

  @include desktop {
    border-left: $border-width solid $border-color;
    width: $sidebar-width;

    &[data-show="false"] {
      right: -$sidebar-width;
    }
  }
}

.outer {
  border-top: $border-width solid $border-color;
  max-height: calc(100vh - #{$header-height});
  min-height: calc(100vh - #{$header-height});
}

.inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: $page-padding-y-mobile $page-padding-x-mobile;

  @include tablet {
    padding: $page-padding-y $page-padding-x;
  }
}

.backdrop {
  background: transparentize($modal-backdrop-bg,  1-$modal-backdrop-opacity);
  bottom: 0;
  height: 100vh;
  left: 0;
  margin: 0 !important;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  transition: $transition-base;
  z-index: $zindex-modal-backdrop - 2;

  *[data-show="true"] + & {
    opacity: 1;
  }
}
