@keyframes ellipsis {
  0%   { content: "..."; }
  25%  { content: " "; }
  50%  { content: "."; }
  75%  { content: ".."; }
}

.loading {
  &::after {
    animation: ellipsis 1.5s infinite linear;
    content: "...";
    display: inline-block;
    text-align: left;
    width: 1.3em;
  }
}
