@import 'css/custom/_required.scss';

.textarea {
  &:global(.form-control) {
    height: calc(#{$input-line-height * 1em * 3} + #{$input-padding-y * 2} + #{$input-height-border});
    min-height: $input-height;

    &.sm {
      height: calc(#{$input-line-height-sm * 1em * 3} + #{$input-padding-y-sm * 2} + #{$input-height-border});
      min-height: $input-height-sm;
    }
  }
}

.disabled {
  resize: none;
}
