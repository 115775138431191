@use 'sass:math';
@import 'css/custom/_required.scss';

.item {
  overflow: visible;
}

.link {
  margin-left: -(px-to-rem($sprite-size-md) + $menu-padding-x * 2);
  padding-left: px-to-rem($sprite-size-md) + $menu-padding-x * 2 !important;

  &:global(.active) {
    &:not(:global(.submenu-toggle-link)) {
      @include before {
        background-color: $primary;
        height: 100%;
        left: 0;
        width: .5rem;
      }
    }
  }

  @include mobile {
    padding-top: 0;
  }

  @include desktop {
    padding-bottom: $menu-padding-y;
    padding-top: $menu-padding-y;
  }
}
