@import 'css/custom/_required.scss';

.inputGroup {
  flex-wrap: nowrap;

  &:not(.vertical):not(.columns):not(.fullWidth) {
    @include media-breakpoint-up(md) {
      max-width: $input-max-width;
    }
  }

  &:not(.disabled):not(.readOnly) {
    &:hover {
      :global(.form-control),
      :global(.input-group-text) {
        @extend %input-hover;
      }
    }
  }
}
