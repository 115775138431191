@import 'css/custom/_required.scss';

.container {
  border-bottom: $border-width solid $border-color;
  padding: $menu-padding-y 0 0;
}

.label {
  color: $primary;
  display: block;
  font-size: $font-size-sm;
  padding: 0 $menu-padding-x;
}

.toggle {
  border: 0;
  font-weight: $font-weight-normal;
  margin-bottom: ($menu-padding-y - $btn-padding-y);
  padding: 0 $menu-padding-x;

  :global(.btn-label) {
    color: $body-color;
  }

  @include hover {
    :global(.btn-sprite) {
      color: $primary;
    }
  }
}

.list {
  border-top: $border-width solid $border-color;
  display: flex;
  flex-direction: column;
  margin-left: $menu-padding-x;
  padding: $btn-padding-y 0;
}

.item {
  border: 0;
  border-radius: 0;
  color: $body-color !important;
  font-weight: $font-weight-normal;
  margin-left: -$menu-padding-x;
  padding: 0 $menu-padding-x;
  width: auto !important;

  @include hover {
    background-color: $accent;
    color: $primary;
  }

  @include disabled {
    background-color: transparent;
    color: $secondary !important;

    :global(.btn-sprite) {
      color: $primary;
    }
  }
}
