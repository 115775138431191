// MOBILE/DESKTOP

@mixin mobile {
  @include media-breakpoint-down($mobile-breakpoint-down) {
    @content;
  }
}

@mixin tablet {
  @include media-breakpoint-up(md) {
    @content;
  }
}

@mixin desktop {
  @include media-breakpoint-up($mobile-breakpoint-up) {
    @content;
  }
}

@mixin hidden-mobile {
  @include mobile {
    display: none !important;
  }
}

@mixin hidden-desktop {
  @include desktop {
    display: none !important;
  }
}


// PSEUDO-ELEMENTS

@mixin pseudo-element {
  content: "";
  display: block;
  position: absolute;
}

@mixin before {
  position: relative;

  &:before {
    @include pseudo-element;
    @content;
  }
}

@mixin after {
  position: relative;

  &:after {
    @include pseudo-element;
    @content;
  }
}


// TYPOGRAPHY

@mixin alignLeft {
  justify-content: flex-start;
  text-align: left;
}

@mixin alignRight {
  justify-content: flex-end;
  text-align: right;
}

@mixin alignCenter {
  justify-content: center;
  text-align: center;
}

@mixin caption {
  color: $caption-color;
  font-size: $caption-size;
  letter-spacing: .1rem;
  line-height: $caption-line-height;
  text-transform: uppercase;
}


// INPUT/BUTTONS

@mixin autofill {
  &:-internal-autofill-selected,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    &,
    & + .input-group-append .input-group-text {
      @content;
    }
  }
}

@mixin focus {
  &:focus,
  &:global(.focus) {
    @content;
  }
}

@mixin active {
  &:active,
  &:global(.active) {
    @content;
  }
}

@mixin disabled {
  &:disabled,
  &:global(.disabled) {
    @content;
  }
}
