@import 'css/custom/_required.scss';

.row {
  height: 100%;
  margin: 0 (-$table-padding-x/2);
}

.col {
  display: flex;
  line-height: 2rem;
  padding: $table-padding-y $table-padding-x/2;
  word-break: break-word;

  @include mobile {
    padding: $table-padding-y/2 $table-padding-x/2;
  }

  @include desktop {
    min-height: 5rem;
  }

  :global(.search-select) {
    height: $btn-height-sm;
    margin: -($btn-height-sm - 2.2rem)/2 0;

    :global(.btn) {
      height: $btn-height-sm - $border-width * 2;
      padding: $btn-padding-y-sm $btn-padding-x-sm;
    }
  }

  :global(.ampersand) {
    display: none;
  }

  :global(.form-group) {
    margin: -.5rem 0;

    :global(.form-control-plaintext) {
      font-weight: inherit;
      margin: .5rem 0;
    }
  }

  & > :global(.form-row) {
    flex-grow: 1;
  }

  :global(.badge),
  :global(.badge-tooltip) {
    height: $font-size-base * $line-height-base * 1.5;
    margin: -.5rem 0;

    :global(.badge) {
      margin: 0;
    }

    @include desktop {
      flex-grow: 1;
    }

    :global(.badge) {
      width: 100%;
    }
  }

  :global(.btn):not(:global(.btn-link)) {
    margin: ($line-height-base * $font-size-base - $btn-height)/2 0;
  }

  :global(.btn-sm):not(:global(.btn-link)) {
    margin: ($line-height-base * $font-size-base - $btn-height-sm)/2 0;
  }

  :global(.btn-lg):not(:global(.btn-link)) {
    margin: ($line-height-base * $font-size-base - $btn-height-lg)/2 0;
  }
}

.label {
  color: $text-muted;
  font-size: $font-size-sm;
  line-height: $font-size-base * $line-height-base;
  padding: $table-padding-y $table-padding-x/2;
  text-align: right;

  @include mobile {
    padding: $table-padding-y/2 $table-padding-x/2;
  }

  @include desktop {
    display: none;
  }
}

.empty {
  color: $text-muted;
}

.list {
  display: flex;
  flex-direction: column;
}
