@import 'css/custom/_required.scss';

.group {
  flex-grow: 0;
  flex-wrap: nowrap;
  width: auto;

  :global(.row) > & {
    margin: 0;
  }

  & > *:not(:last-child) {
    margin-right: -$border-width;
    padding-right: 0;

    :global(.form-control),
    :global(.input-group-append .input-group-text) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  & > *:not(:first-child){
    padding-left: 0;

    :global(.form-control),
    :global(.input-group-prepend .input-group-text) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  :global(.input-group) {
    @include hover {
      z-index: 1;
    }
  }

  :global(.col):not(:first-child) :global(.btn) {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  :global(.col):not(:last-child) :global(.btn) {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}
