@import 'css/custom/_required.scss';

@mixin active-item($zindex: 1) {
  position: relative;
  z-index: $zindex;
  margin-bottom: -$border-width;
  padding-bottom: $menu-padding-y + $border-width !important;

  .item:not(:first-child) > &,
  :global(.submenu-list) > .item > & {
    margin-top: -$border-width;
    padding-top: $menu-padding-y + $border-width !important;
  }
}

.item {
  display: flex;

  &:not(:first-child) {
    border-top: $border-width solid $border-color;
  }
}

.link {
  @include caption;

  align-items: center;
  border: 0;
  display: flex;
  flex-grow: 1;
  margin-left: -$menu-padding-x;
  padding: $menu-padding-y $menu-padding-x !important;

  .icon, .label {
    transition: $transition-base;
  }

  .icon {
    color: $secondary;
    margin-right: $menu-padding-x;
    width: 2rem;
  }

  .label {
    color: $body-color;
  }

  @include hover {
    background-color: $accent !important;
    @include active-item;

    .icon {
      color: $primary;
    }
  }

  &:global(.active) {
    background-color: $body-bg;
    @include active-item(2);

    .icon {
      color: $primary;
    }

    @include before {
      background-color: $primary;
      height: 100%;
      left: 0;
      width: .5rem;
    }
  }
}

.button {
  border-top: 0 !important;

  :global(.btn),
  :global(.nav-link) {
    background-color: $link-color !important;
    color: $white !important;

    :global(.btn-label),
    :global(.menu-label),
    :global(.sprite) {
      color: $white !important;
    }

    @include hover {
      &:not(:global(.disabled)) {
        background-color: $link-hover-color !important;
        color: $white !important;

        :global(.btn-label),
        :global(.menu-label),
        :global(.sprite) {
          color: $white !important;
        }
      }
    }
  }
}
