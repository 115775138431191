@import 'css/custom/_required.scss';

.badge {
  color: $white;
  font-weight: $font-weight-bolder;
  letter-spacing: normal;
  padding: .2rem .5rem;

  &:last-child {
    margin-left: auto;
  }
}
