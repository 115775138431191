@import 'css/custom/_required.scss';

.item {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: $input-font-size;
  justify-content: space-between;
  line-height: $input-line-height;
  padding: $input-padding-y $input-padding-x !important;

  &[data-selected="true"] {
    background: $component-active-bg;
    color: $component-active-color;
  }

  &[data-selected="false"]:hover {
    background: mix($component-active-bg, $dropdown-bg, 30%);
    color: $component-active-color;
  }
}

.label {
  white-space: nowrap;
}

.icon {
  margin-left: $input-padding-x;

  .item[data-selected="false"] > & {
    opacity: 0;
  }
}
