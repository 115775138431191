@import 'css/custom/_required.scss';

.outer {
  width: 20rem;

  &:not(.inline):not(.vertical):not(.columns):not(.fullWidth) {
    @include media-breakpoint-up(md) {
      max-width: calc(100% + #{$form-grid-gutter-width});
      width: calc(100% + #{$form-grid-gutter-width});

      @include mobile {
        max-width: calc(100% + #{$form-grid-gutter-width-mobile});
        width: calc(100% + #{$form-grid-gutter-width-mobile});
      }
    }
  }
}
