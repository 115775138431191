@import 'css/custom/_required.scss';

.dialog {
  flex-direction: column;
  justify-content: space-around;
  margin: 0;
  width: $modal-md;

  &:before {
    display: none;
  }

  &.xl {
    justify-content: flex-start;
    max-width: calc(100vw - #{$modal-padding * 2});
    width: $modal-xl;
  }

  @include media-breakpoint-down(sm) {
    &.xl {
      margin: -$modal-padding;
      max-width: 100vw;

      .content {
        padding-bottom: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    max-width: $modal-lg;
    width: $modal-lg;

    &.xl {
      max-width: $modal-xl;
    }
  }

  .content {
    background-color: transparent;
    box-shadow: $box-shadow;
    padding: 0 0 $modal-padding;

    & > div {
      background-color: $white;
    }
  }
}

.header {
  flex-direction: column;
  margin: 0;
  padding: 5rem 5rem 0;
}

.graphic {
  margin: 0 auto 2.4rem;
}

.title {
  font-weight: $font-weight-bolder;
  margin: 0 auto;
  text-align: center;
}

.body {
  margin: 0 auto;
  padding: 2.5rem 5rem;
  text-align: center;

  .xl & {
    text-align: left;
  }

  &:first-child {
    padding-top: 5rem;
  }

  &:last-child {
    padding-bottom: 5rem;
  }
}

.footer {
  display: block;
  margin: 0;
  padding: 0 5rem 5rem;
  width: 100%;

  & > * {
    margin: 0;
  }
}

.buttons {
  justify-content: space-around;
}

.close {
  position: fixed;
  right: $modal-padding;
  top: $modal-padding;

  .xl + & {
    @media screen and (max-width: #{rem-to-px($modal-xl + $modal-padding * 4 + $btn-height * 2)}) {
      background-color: transparent !important;
      border-color: transparent !important;
      box-shadow: none !important;
      color: $secondary !important;
      position: absolute;
      right: calc(50% - #{$modal-xl/2});

      &:hover {
        color: $primary !important;
      }
    }

    @media screen and (max-width: #{rem-to-px($modal-xl + $modal-padding * 2)}) {
      right: $modal-padding;
    }

    @include media-breakpoint-down(sm) {
      right: 0;
      top: 0;
    }
  }
}
