// Color system

$white:    #fff !default;
$gray-100: #FAFBFC;
$gray-200: #ECF1F6;
$gray-300: #E8ECF1;
$gray-400: #ced4da !default;
$gray-500: #A4A8AE;
$gray-600: #7F858C;
$gray-700: #474F58;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #07F;
$indigo:  #6610f2 !default;
$purple:  #c867af;
$pink:    #e83e8c !default;
$red:     #FC4F35; //#FF1744
$orange:  #f0ad4e;
$yellow:  #FFD012;
$green:   #80D135;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$primary:       $blue !default;
$secondary:     $gray-500;
$success:       $green;
$info:          $purple;
$warning:       $orange;
$danger:        $red !default;
$accent:        mix($primary, $white, 10%);

$light:         $gray-100 !default;
$dark:          $gray-700;


// Options

$enable-validation-icons:                     false;


// Spacing

$spacer: 1rem !default; // Assumes html font size is 10px.
$spacers: (
  0: 0,
  1: $spacer,
  2: ($spacer * 1.2),
  3: ($spacer * 1.5),
  4: ($spacer * 2),
  5: ($spacer * 3)
);
$text-spacer: $spacer * 1.2; // Custom parameter


// Body

$body-bg:                   $gray-200;
$body-color:                $black;
$body-size:                 10px;


// Links

$link-color:                $blue;
$link-hover-color:          darken($link-color, 20%);


// Paragraphs

$paragraph-margin-bottom:   .8rem;


// Grid breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: 470px,
  md: 768px,
  lg: 1024px,
  xl: 1440px
);
$mobile-breakpoint-up:   'lg';
$mobile-breakpoint-down: 'md';


// Grid columns

$grid-columns:                24;


// Components

$line-height-sm:              1.166666666666667;
$line-height-lg:              1.25;

$border-width:                .1rem;
$border-color:                mix($white, $secondary, 80%);

$border-radius:               .4rem;
$border-radius-lg:            $border-radius;
$border-radius-sm:            $border-radius;

$box-shadow:                  0px 2px 9px rgba(0, 0, 0, 0.02);
$box-shadow-lg:               0px 2px 12px rgba(28, 56, 147, 0.1);

$component-active-color:      $primary;
$component-active-bg:         $gray-200;

$transition-time:             .2s; // Custom parameter
$transition-base:             all $transition-time ease-in-out;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;


// Typoegraphy

$font-family-sans-serif:      Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-condensed:       "Roboto Condensed", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; // Custom parameter
$font-size-base:              1.4rem;
$font-size-xl:                1.8rem; // Custom parameter
$font-size-lg:                1.6rem;
$font-size-sm:                1.2rem;
$font-size-xs:                1.0rem;

$font-weight-normal:          normal;
$font-weight-bolder:          500;
$font-weight-bold:            600;

$line-height-base:            1.428571428571429;

$h1-font-size:                3.2rem;
$h2-font-size:                2.4rem;
$h3-font-size:                1.8rem;
$h4-font-size:                1.6rem;
$h5-font-size:                1.4rem;

$headings-margin-bottom:      0;
$headings-font-weight:        $font-weight-normal;

$h1-line-height:              4.2rem; // Custom parameter
$h2-line-height:              2.8rem; // Custom parameter
$h3-line-height:              2.4rem; // Custom parameter
$h4-line-height:              2rem; // Custom parameter
$h5-line-height:              2rem; // Custom parameter

$caption-color:               $secondary; // Custom parameter
$caption-size:                1.2rem; // Custom parameter
$caption-line-height:         1.166666666666667; // Custom parameter

$small-font-size:             $font-size-sm;

$text-muted:                  #888;
$text-label:                  transparentize($black, .4); // Custom parameter

$hr-border-color:             $border-color;


// Buttons + Forms

$input-btn-padding-y:         1rem - $border-width;
$input-btn-padding-x:         1.6rem - $border-width;
$input-btn-font-size:         $font-size-base;
$input-btn-line-height:       $line-height-base;

$input-btn-focus-width:       0;

$input-btn-padding-y-sm:      .5rem - $border-width;
$input-btn-padding-x-sm:      1rem - $border-width;
$input-btn-font-size-sm:      $input-btn-font-size;
$input-btn-line-height-sm:    $input-btn-line-height;

$input-btn-padding-y-lg:      2rem - $border-width;
$input-btn-padding-x-lg:      2.4rem - $border-width;
$input-btn-font-size-lg:      $font-size-lg;
$input-btn-line-height-lg:    $line-height-lg;


// Buttons

$btn-disabled-opacity:        1;
$btn-line-height:             2rem/$input-btn-font-size;

$btn-font-weight:             $font-weight-bolder;

$btn-height:                  $input-btn-font-size * $input-btn-line-height + $input-btn-padding-y * 2 + $border-width * 2; // Custom parameter
$btn-height-sm:               $input-btn-font-size-sm * $input-btn-line-height-sm + $input-btn-padding-y-sm * 2 + $border-width * 2; // Custom parameter
$btn-height-lg:               $input-btn-font-size-lg * $input-btn-line-height-lg + $input-btn-padding-y-lg * 2 + $border-width * 2; // Custom parameter

$btn-block-spacing-y:         0;


// Forms

$label-width:                           30rem; // Custom parameter
$label-margin-bottom:                   0;
$label-required-indicator:              "*"; // Custom parameter

$input-font-family:                     $font-family-sans-serif;

$input-bg:                              $white;

$input-color:                           $body-color;
$input-icon-color:                      $input-color; // Custom parameter
$input-border-color:                    $border-color;
$input-box-shadow:                      none;

$input-placeholder-color:               transparentize($secondary, .4);

$input-focus-border-color:              mix($border-color, $dark, 75%);

$input-height:                          2rem + $input-btn-padding-y * 2 + $border-width * 2; // Fixes rounding error on line height

$input-empty-bg:                        $white;
$input-empty-border-color:              $input-border-color;

$input-disabled-bg:                     transparentize($input-bg, .25);
$input-disabled-color:                  $secondary; // Custom parameter
$input-disabled-border-color:           transparentize($input-border-color, .5); // Custom parameter

$input-max-width:                       40rem; // Custom parameter

$custom-control-label-color:            $dark;
$custom-control-label-disabled-color:   $secondary;
$custom-control-label-weight:           $font-weight-normal;

$form-text-margin-top:                  0;

$form-grid-gutter-width:                2rem;
$form-grid-gutter-width-mobile:         1.6rem; // Custom parameter
$form-group-margin-bottom:              2.4rem;

$form-group-padding:                    15px; // Custom parameter
$form-group-padding-mobile:             10px; // Custom parameter

$input-group-addon-color:               $input-border-color;
$input-group-addon-bg:                  $input-bg;


// Dropdowns

$dropdown-padding-y:                0;
$dropdown-spacer:                   0;
$dropdown-bg:                       $white !default;
$dropdown-border-color:             $border-color;
$dropdown-border-radius:            0;
$dropdown-inner-border-radius:      0;
$dropdown-box-shadow:               $box-shadow;

$dropdown-link-hover-bg:            transparent;

$dropdown-item-padding-y:           1.5rem;
$dropdown-item-padding-x:           3rem;

$dropdown-caret-size:               1rem; // Custom parameter


// Cards

$card-spacer-y:                     2rem;
$card-spacer-x:                     3rem;
$card-border-width:                 0;
$card-border-radius:                0;
$card-cap-bg:                       transparent;
$card-bg:                           $light;


// Tooltips

$tooltip-font-size:                 $font-size-base;
$tooltip-max-width:                 300px;
$tooltip-color:                     $white !default;
$tooltip-bg:                        $primary;
$tooltip-border-radius:             0;
$tooltip-opacity:                   1;
$tooltip-padding-y:                 1rem;
$tooltip-padding-x:                 1.5rem;
$tooltip-margin:                    .5rem;

$tooltip-arrow-width:               1.2rem;
$tooltip-arrow-height:              .6rem;
$tooltip-arrow-color:               $tooltip-bg !default;


// Badges

$badge-height:                      1.6rem;   // Custom parameter

$badge-font-size:                   $font-size-sm;
$badge-font-weight:                 $font-weight-normal;
$badge-padding-y:                   0;
$badge-padding-x:                   .2rem;
$badge-border-radius:               $border-radius-sm;

$badge-pill-padding-x:              .4rem;
$badge-pill-border-radius:          $badge-height;


// Modals

$modal-content-border-width:        0;

$modal-backdrop-bg:                 $dark;
$modal-backdrop-opacity:            .6;
$modal-padding:                     3rem; // CUSTOM PARAMETER
$modal-content-border-width:        0;
$modal-content-border-radius:       0;

$modal-sm:                          30rem;
$modal-md:                          41rem;
$modal-lg:                          52rem;
$modal-xl:                          90rem;


// Alerts

$alert-padding-y:                   $input-btn-padding-y;
$alert-padding-x:                   $input-btn-padding-x;
$alert-border-radius:               0;
$alert-border-width:                0;
$alert-font-size:                   $input-btn-font-size; // Custom parameter
$alert-line-height:                 $input-btn-line-height; // Custom parameter

$alert-bg-level:                    -8.5;
$alert-border-level:                0;
$alert-color-level:                 6;


// List group

$list-group-bg:                     transparent;
$list-group-border-color:           $border-color;

$list-group-item-padding-y:         0;
$list-group-item-padding-x:         0;


// Pagination

$pagination-padding-y:              .5rem !default;
$pagination-padding-x:              .75rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;
$pagination-line-height:            1.25 !default;

$pagination-color:                  $white;
$pagination-bg:                     $primary;
$pagination-border-width:           0;

$pagination-focus-box-shadow:       none;

$pagination-hover-color:            $pagination-color;
$pagination-hover-bg:               $link-hover-color;

$pagination-active-color:           $pagination-color;
$pagination-active-bg:              $link-hover-color;

$pagination-disabled-color:         $gray-600 !default;
$pagination-disabled-bg:            transparent;


// Custom

$logo-height:                 48px;
$logo-width:                  156px;
$logo-small:                  48px; // must be px
$logo-offset:                 -1.6rem;

$sprite-size-lg:              24px; // must be px
$sprite-size-md:              20px; // must be px
$sprite-size-sm:              18px; // must be px
$sprite-size-xs:              16px; // must be px

$scrollbar-bg:                $gray-200;
$scrollbar-color:             $gray-400;
$scrollbar-hover:             $primary;
$scrollbar-width:             .75rem;
$scrollbar-padding:           .5rem;
$scrollbar-border-radius:     $border-radius;

$menu-lg:                     28rem;
$menu-sm:                     8rem;
$menu-padding-y:              1.5rem;
$menu-padding-x:              1.5rem;

$interface-header-padding-y:  1.2rem;
$interface-header-padding-x:  3rem;

$header-height:               8rem;
$header-padding:              3rem;

$module-padding:              3rem;
$module-padding-mobile:       2rem;

$table-padding-y:             1.5rem;
$table-padding-x:             2rem;

$page-padding-y:              3rem;
$page-padding-x:              3rem;
$page-padding-y-mobile:       1.5rem;
$page-padding-x-mobile:       1.5rem;

$sidebar-bg:                  $light;
$sidebar-width:               $label-width + $input-max-width + $form-grid-gutter-width + $page-padding-x * 2 + $border-width;

$toggle-active-box-shadow:    0px 4px 12px rgba(0, 8, 51, 0.1);

// Disable RFS by setting $enable-responsive-font-sizes to false
$enable-responsive-font-sizes: false;
